import { PortableTextMainContent } from '/features/buildingBlocks/PortableText'
import { HeadingSm } from '/features/buildingBlocks/Heading'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './FAQElement.css'

import chevronIcon from '/images/icons/chevron-circle-down.raw.svg'

export function FAQElement({ items, layoutClassName = undefined }) {
  const [activeItem, setActiveItem] = React.useState(null)

  return (
    <div className={cx(styles.component, layoutClassName)}>
      {items && items.map((item, i) =>
        <Item
          key={i}
          onClick={() => handleActiveItem(i)}
          isOpen={i === activeItem}
          {...{ item }}
        />
      )}
    </div>
  )

  function handleActiveItem(i) {
    setActiveItem(i === activeItem ? null : i)
  }
}

function Item({ item, onClick, isOpen }) {
  const { question, answer } = item ?? {}
  const ariaId = question?.replace(/\s+/g, '-').toLowerCase()

  return (
    <div
      aria-expanded={isOpen}
      aria-labbelledby={ariaId}
      className={cx(styles.componentItem, isOpen && styles.buttonIsOpen)}
    >
      <div className={styles.questionAndAnswer}>
        {question && <Question {...{ question, isOpen, onClick, ariaId }} />}
        {isOpen && <Answer {...{ answer }} />}
      </div>
      <Backdrop layoutClassName={styles.backdropLayout} {...{ isOpen }} />
    </div>
  )
}

function Question({ question, isOpen, onClick, ariaId }) {
  return (
    <button
      aria-controls={ariaId}
      className={styles.componentQuestion}
      {...{ onClick }}
    >
      <HeadingSm h='3' title={question} />
      <div className={cx(styles.iconWrapper, isOpen && styles.iconIsOpen)}>
        <Icon icon={chevronIcon} layoutClassName={styles.iconLayout} />
      </div>
    </button>
  )
}

function Answer({ answer }) {
  return (
    <div className={styles.componentAnswer}>
      <PortableTextMainContent value={answer} />
    </div>
  )
}

function Backdrop({ isOpen, layoutClassName }) {
  return <span className={cx(styles.componentBackdrop, isOpen && styles.backdropShow, layoutClassName)} />
}
